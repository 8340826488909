@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");

.App {
  font-family: Quicksand;
}

.App-feuilles-t-l {
  background-image: url(./images/feuilles-top-left.png);
  background-size: cover;
  width: 150px;
  height: 98px;
  top: -100%;
  opacity: 0;
  left: -100%;
}

@media (min-width: 640px) {
  .App-feuilles-t-l {
    width: 290px;
    height: 189px;
  }
}

@media (min-width: 1200px) {
  .App-feuilles-t-l {
    width: 582px;
    height: 379px;
  }
}

.App-feuilles-t-l-enter {
  opacity: 0;
  top: -100%;
  left: -100%;
}

.App-feuilles-t-l-enter-active {
  opacity: 1;
  top: -5px;
  left: -12px;
  transition: opacity 200ms, left 800ms, top 800ms;
}

.App-feuilles-t-l-enter-done {
  opacity: 1;
  top: -5px;
  left: -12px;
}

.App-feuilles-t-r {
  background-image: url(./images/feuilles-top-right.png);
  background-size: cover;
  width: 150px;
  height: 160px;
  top: -100%;
  right: -100%;
}

@media (min-width: 640px) {
  .App-feuilles-t-r {
    width: 331px;
    height: 353px;
  }
}

@media (min-width: 1200px) {
  .App-feuilles-t-r {
    width: 662px;
    height: 707px;
  }
}

.App-feuilles-t-r-enter {
  opacity: 0;
  top: -100%;
  right: -100%;
}

.App-feuilles-t-r-enter-active {
  opacity: 1;
  top: -5px;
  right: -12px;
  transition: opacity 200ms, right 700ms, top 700ms;
}

.App-feuilles-t-r-enter-done {
  opacity: 1;
  top: -5px;
  right: -12px;
}

.App-feuilles-b-l {
  background-image: url(./images/feuilles-bottom-left.png);
  background-size: cover;
  width: 150px;
  height: 200px;
  bottom: -8px;
  left: -100%;
}

@media (min-width: 640px) {
  .App-feuilles-b-l {
    width: 375px;
    height: 503px;
  }
}

@media (min-width: 1200px) {
  .App-feuilles-b-l {
    width: 751px;
    height: 1006px;
  }
}

.App-feuilles-b-l-enter {
  opacity: 0;
  left: -100%;
}

.App-feuilles-b-l-enter-active {
  opacity: 1;
  left: -10px;
  transition: opacity 200ms, left 600ms;
}

.App-feuilles-b-l-enter-done {
  opacity: 1;
  left: -10px;
}

.App-feuilles-b-r {
  background-image: url(./images/feuilles-bottom-right.png);
  background-size: cover;
  width: 150px;
  height: 177px;
  bottom: -8px;
  right: -100%;
}

@media (min-width: 640px) {
  .App-feuilles-b-r {
    width: 327px;
    height: 386px;
  }
}

@media (min-width: 1200px) {
  .App-feuilles-b-r {
    width: 655px;
    height: 772px;
  }
}

.App-feuilles-b-r-enter {
  opacity: 0;
  right: -100%;
}

.App-feuilles-b-r-enter-active {
  opacity: 1;
  right: -12px;
  transition: opacity 200ms, right 800ms;
}

.App-feuilles-b-r-enter-done {
  opacity: 1;
  right: -12px;
}

.App-georges {
  top: -100%;
  left: 5vh;
  opacity: 0;
  max-width: 150px;
  height: auto;
}

@media (min-width: 640px) {
  .App-georges {
    left: 10px;
    max-width: 250px;
  }
}

@media (min-width: 980px) {
  .App-georges {
    max-width: 400px;
  }
}

@media (min-width: 1500px) {
  .App-georges {
    max-width: none;
  }
}

@media (min-width: 1800px) {
  .App-georges {
    left: 20vh;
  }
}

.App-georges-enter {
  opacity: 0;
  top: -100%;
}

.App-georges-enter-active {
  opacity: 1;
  top: -30px;
  transition: opacity 200ms, top 1400ms;
}

.App-georges-enter-done {
  opacity: 1;
  top: -30px;
}

.App-toucan {
  right: -100%;
  max-width: 150px;
  height: auto;
  bottom: 10px;
}

@media (min-width: 640px) {
  .App-toucan {
    bottom: 5vh;
    max-width: 200px;
  }
}

@media (min-width: 980px) {
  .App-toucan {
    max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .App-toucan {
    top: 40vh;
    bottom: auto;
    max-width: none;
  }
}

.App-toucan-b-r-enter {
  opacity: 0;
  right: -100%;
}

.App-toucan-enter-active {
  opacity: 1;
  right: 0;
  transition: opacity 200ms, right 1000ms;
}

.App-toucan-enter-done {
  opacity: 1;
  right: 0;
}
